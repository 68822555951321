.page0-title {
    left: 0;
    position: absolute;
    right: 0;
    top: 7.8%;

    div {
        font-size: 13px;
        font-weight: bold;

        &:first-child {
            font-size: 1.5em;
        }
    }
}

.page0-image {
    bottom: 17.6%;
    left: 14%;
    position: absolute;
    right: 14%;
    top: 20.9%;

    img {
        height: auto;
        width: 100%;
    }
}

.page0-button {
    bottom: 4.3%;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: none;

    .clickable {
        background-color: rgba(white, 0.1);
        border-radius: 4px;
        display: inline-block;
        line-height: 1em;
        padding: 1em 1.2em;
    }
}
