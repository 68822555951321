svg {
    overflow: visible;
}

.page-title {
    background-color: #231F20;
    font-size: 22px;
    font-weight: bold;
    left: 17%;
    padding: 0.7em .5em;
    position: absolute;
    right: 17%;
    top: 0;
}

.page-container {
    bottom: 9em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 19.1%;
    position: absolute;
    right: 19.1%;
    top: 6em;
    max-width: 70em;
    margin: 0 auto;
    
    .page-image {
        -webkit-box-align: stretch;
            -ms-flex-align: stretch;
                align-items: stretch;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;

        .page-bars {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
                -ms-flex: 1 1 50%;
                    flex: 1 1 50%;
            font-size: 11px;
            width: 100%;
            -webkit-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
            div {
                -webkit-box-flex: 1;
                    -ms-flex: 1 1 25%;
                        flex: 1 1 25%;
                position: relative;
                text-align: center;
                span {
                    background: white;
                    bottom: 0;
                    left: 50%;
                    margin-left: -2px;
                    position: absolute;
                    -webkit-transition: top 1s ease-in-out;
                    transition: top 1s ease-in-out;
                    width: 4px;
                    /* &:before {
                        content: "";
                        background-image: url('/assets/microphone.svg');    
                        position: absolute;
                        top: -90px;
                        width: 70px;
                        height: 90px;
                        background-repeat: no-repeat;
                        left: -15px;
                    } */
                }
            }
            .microphone {
                -webkit-transition: top 1s ease-in-out;
                transition: top 1s ease-in-out;
                width: 3.2em;
                position: absolute;
                z-index: 1;
                margin-top: -96px;
                margin-left: -17px;
                left: 50%;
            }
        }

        .page-heads {
            margin-top: auto;
            width: 100%;

            svg {
                width: 100%;
                height: auto;
            }

            .head {
                width: 25%;
                float: left;
            }
            
            img {
                height: auto;
                margin-top: auto;
                width: 100%;
            }
        }
    }

    .page-labels {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 0 1 0%;
                flex: 0 1 0%;
        font-size: 11px;
        left: 0;

        div {
            -webkit-box-flex: 1;
                -ms-flex: 1 1 25%;
                    flex: 1 1 25%;
            position: relative;
        }
    }
}

.page-button,
.page-previous,
.page-next {
    bottom: 4.3%;
    position: absolute;

    .clickable {
        display: inline-block;
        line-height: 1em;
    }
 }

.page-button {
    left: 0;
    right: 0;
    text-align: center;
    .clickable {
        background-color: rgba(black, 0.1);
        border-radius: 4px;
        padding: 1em 1.2em;
    }
}

.page-14 {
    .page-button {
        visibility: visible;
    }
}

.page-previous {
    right: 60%;
    text-align: right;
    z-index: 10;
    .clickable {
        padding: 1em 0;
    }
}

.page-next {
    left: 60%;
    text-align: left;

    .clickable {
        padding: 1em 0;
    }
}
.page-container-outer {
    max-width: 40em;
    margin: 0 auto;
}
#app_inner.elems-total {
    .page-bars,
    .page-labels {
        opacity: 0;
    }
    .page-heads {
        max-width: 30em;
        margin: 0 auto;
    }
    .page-container {
        align-items: center;
    }
}

.head {
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    &.total-animation {
        #lennon_total_bg {
            -webkit-transform: translate(-109px, -87px) scale(1);
            -ms-transform: translate(-109px, -87px) scale(1);
                    transform: translate(-109px, -87px) scale(1);
            transition: all 1s ease-out;
            -webkit-transform-origin: center center;
                    transform-origin: center center;
            -webkit-transition-delay: 0.1s;
                    transition-delay: 0.1s;
                    fill-opacity: 0.5;
        }
        #mccartney_total_bg {
            -webkit-transform: translate(-109px, -87px) scale(1);
            -ms-transform: translate(-109px, -87px) scale(1);
                    transform: translate(-109px, -87px) scale(1);
            transition: all 1s ease-out;
            -webkit-transform-origin: center center;
                    transform-origin: center center;
            -webkit-transition-delay: 0s;
                    transition-delay: 0s;
                    fill-opacity: 0.5;
        }
        #harrison_total_bg {
            -webkit-transform: translate(-52px, -30px) scale(1);
            -ms-transform: translate(-52px, -30px) scale(1);
                    transform: translate(-52px, -30px) scale(1);
            transition: all 1s ease-out;
            -ms-transform-origin:  50% 50%;
            -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transition-delay: 0.3s;
                    transition-delay: 0.3s;
                    fill-opacity: 0.5;
        }
        #ringo_total_bg {
            -webkit-transform: translate(-9px, 0px) scale(1);
            -ms-transform: translate(-9px, 0px) scale(1);
                    transform: translate(-9px, 0px) scale(1);
            -webkit-transition: all 1s ease-out;
            -ms-transform-origin: 50% 50%;
            -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            transition: all 1s ease-out;
            -webkit-transition-delay: 0.4s;
                    transition-delay: 0.4s;
                    fill-opacity: 0.5;
        }
        .total-songs {
            -webkit-transition: all 0.4s ease-in;
            transition: all 0.4s ease-in;
            -webkit-transition-delay: 1.5s;
                    transition-delay: 1.5s;
            visibility: visible;
            opacity: 1;
        }
    }
    &#head_lennon.total-animation {
        -webkit-transform: translate(50%, -60%);
        -ms-transform: translate(50%, -60%);
                transform: translate(50%, -60%);
        transition: all 0.3s ease-in;
    }
    &#head_mccartney.total-animation {
        -webkit-transform: translate(150%, -60%);
        -ms-transform: translate(150%, -60%);
                transform: translate(150%, -60%);
        transition: all 0.3s ease-in;
    }
    &#head_harrison.total-animation {
        -ms-transform: translate(-150%, 130%);
        -webkit-transform: translate(-150%, 130%);
                transform: translate(-150%, 130%);
        transition: all 0.3s ease-in;
    }
    &#head_ringo.total-animation {
        -webkit-transform: translate(-50%, 130%);
        -ms-transform: translate(-50%, 130%);
                transform: translate(-50%, 130%);
        transition: all 0.3s ease-in;
    }
    #lennon_total_bg {
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: translate(-109px, -87px) scale(0);
        -ms-transform: translate(-109px, -87px) scale(0);
                transform: translate(-109px, -87px) scale(0);
        transition: all 0s ease-in;
    }
    #mccartney_total_bg {
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: translate(-109px, -87px) scale(0);
        -ms-transform: translate(-109px, -87px) scale(0);
                transform: translate(-109px, -87px) scale(0);
        transition: all 0s ease-in;
    }
    #harrison_total_bg {
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: translate(-52px, -30px) scale(0);
        -ms-transform: translate(-52px, -30px) scale(0);
                transform: translate(-52px, -30px) scale(0);
        transition: all 0s ease-in;
    }
    #ringo_total_bg {
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: translate(-9px, 0px) scale(0);
            -ms-transform: translate(-9px, 0px) scale(0);
                transform: translate(-9px, 0px) scale(0);
        -ms-transition: all 0s ease-in;
        transition: all 0s ease-in;
    }
       
}

.total-songs {
    -webkit-transition: all 0s ease-in;
    transition: all 0s ease-in;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    font-weight: 700;
    color: #196d9a;
    width: 200px;
    text-align: center;
    margin-left: -100px;
}


.browser-ie11 {
    .total-songs {
        top: 120%;
    }
}


.browser-ie {
    .head {
    
        #lennon_total_bg {
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transform: translate(-109px, -87px) scale(1);
                    transform: translate(-109px, -87px) scale(1);
            transition: all 0s ease-in;
        }
        #mccartney_total_bg {
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transform: translate(-109px, -87px) scale(1);
                    transform: translate(-109px, -87px) scale(1);
            transition: all 0s ease-in;
        }
        #harrison_total_bg {
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transform: translate(-52px, -30px) scale(1);
                    transform: translate(-52px, -30px) scale(1);
            transition: all 0s ease-in;
        }
        #ringo_total_bg {
            -webkit-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
            -webkit-transform: translate(-9px, 0px) scale(1);
                    transform: translate(-9px, 0px) scale(1);
            -ms-transition: all 0s ease-in;
            transition: all 0s ease-in;
        }
          
    }
}

@media only screen and (min-width : 1400px) {
   
}

@media only screen and (max-width : 767px) {
    .total-songs {
        font-size: 2vw;
    }
    .page-container-outer {
        max-width: 100%;
        .page-container {
            left: 5%;
            right: 5%;
        }
        .page-image {
            .page-bars {
                .microphone {
                    width: 3em;
                }
            }
        }
    }
    .page-previous {
        right: 80%; 
    }
    .page-next {
        left: 80%; 
    }
    #app_inner.elems-total {
        .page-heads {
            max-width: 75%;
        }
    }

}

@media only screen and (max-width : 600px) {
    .page-container-outer {
        .page-image {
            .page-bars {
                .microphone {
                    width: 2.5em;
                    margin-top: -76px;
                    margin-left: -14px;
                }
            }
        }
    }
    .page-title {
        width: 100%;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width : 460px) {
    .page-container-outer {
        .page-image {
            .page-bars {
                .microphone {
                    width: 2em;
                    margin-top: -60px;
                    margin-left: -11px;
                }
            }
        }
    }
}
