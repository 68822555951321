html,
body {
    -webkit-font-smoothing: antialiased;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    font-family: Arial, Sans-serif;
}

#app,
.fullpage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fullpage {
    transition: background-color .2s ease-in-out;
}

.no-events {
    pointer-events: none;
}
